import { Checkbox } from "./Checkbox";
import { CheckboxGroup } from "./CheckboxGroup";
import { FormDropZone } from "./DropZone";
import { DurationSelect } from "./DurationSelect";
import { Form as FormComponent } from "./Form";
import { FormAnimatedSelect } from "./FormAnimatedSelect";
import { FormColorPicker } from "./FormColorPicker";
import { FormColorPickerV2 } from "./FormColorPickerV2";
import { FormCommunityMemberSelect } from "./FormCommunityMemberSelect";
import { FormEmojiPicker as FormEmojiPickerV3 } from "./FormEmojiPickerV3";
import { EmojiPickerWrapper as FormEmojiPickerV3Wrapper } from "./FormEmojiPickerV3Wrapper";
import { FormInput } from "./FormInput";
import { FormLabel } from "./FormLabel";
import { FormSelect } from "./FormSelect";
import { FormSelectNative } from "./FormSelectNative";
import { FormTextArea } from "./FormTextArea";
import { FormTextSelect } from "./FormTextSelect";
import { Item } from "./Item";
import { LocationPicker } from "./LocationPicker";
import { RadioGroup } from "./RadioGroup";
import { ScorePicker } from "./ScorePicker";
import { SearchInput } from "./SearchInput";
import { ToggleSwitch } from "./ToggleSwitch";

export const Form = Object.assign(FormComponent, {
  Item,
  Label: FormLabel,
  Input: FormInput,
  EmojiPickerV3: FormEmojiPickerV3,
  EmojiPickerV3Wrapper: FormEmojiPickerV3Wrapper,
  TextArea: FormTextArea,
  Select: FormSelect,
  SelectNative: FormSelectNative,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  ToggleSwitch,
  AnimatedSelect: FormAnimatedSelect,
  ColorPicker: FormColorPicker,
  SearchInput,
  ScorePicker,
  LocationPicker,
  DropZone: FormDropZone,
  DurationSelect,
  CommunityMemberSelect: FormCommunityMemberSelect,
  TextSelect: FormTextSelect,
  ColorPickerV2: FormColorPickerV2,
});
